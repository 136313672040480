import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import logo from "../assets/logo.png";
import newslogo1 from "../assets/news-logo01.png";
import newslogo2 from "../assets/news-logo02.png";
import newslogo3 from "../assets/news-logo03.png";
import newslogo4 from "../assets/news-logo04.png";
import newslogo5 from "../assets/news-logo05.png";
import newslogo6 from "../assets/news-logo06.png";
import star from "../assets/star.png";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import arrow from "../assets/arrow.svg";
import testimonial from "../assets/review.png";
import user1 from "../assets/user-1.jpeg";
import user2 from "../assets/user-2.jpeg";
import user3 from "../assets/user-3.jpeg";
import user4 from "../assets/user-4.jpeg";
import fi33887 from "../assets/fi_33887.svg";
import fi33888 from "../assets/fi_681494.svg";
import fi33889 from "../assets/fi_8621814.svg";
import fi33890 from "../assets/fi_1884141.svg";
import InitialScripts from "../script/initialScripts";
import FloatingCard from "../floating-card";
import gasImage from "../assets/petrol-pump.svg";
import groceryImage from "../assets/grocery.svg";
import rentImage from "../assets/rent.svg";
import shoppingImage from "../assets/card.svg";

export default function DefaultPage({ setNumber, cityStateResponse }) {
  const { state, city, zip } = cityStateResponse;
  return (
    <>
      <InitialScripts setNumber={setNumber} />
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img src={logo}></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
              </div>
            </div>
          </div>
          {
            //
            // <div className="logo-strip-section">
            //  <div className="container">
            //    <div className="row">
            //      <div className="logo-strip-headline">
            //        <h1>MENTIONED ON</h1>
            //      </div>
            //      <div className="logo-strip">
            //        <div className="logoitem">
            //          <img src={newslogo1}></img>
            //          <img src={newslogo2}></img>
            //          <img src={newslogo3}></img>
            //          <img src={newslogo4}></img>
            //          <img src={newslogo5}></img>
            //          <img src={newslogo6}></img>
            //        </div>
            //      </div>
            //    </div>
            //  </div>
            // </div>
            //
          }
          <div className="card-section">
            <div className="container">
              <div className="row">
                <div className="custom-container">
                  <div className="icon-pack">
                    <div className="headline-back">
                      <h2>Mentioned On</h2>
                    </div>
                    <div className="logo-icon-pack">
                      <img src={newslogo1}></img>
                      <img src={newslogo2}></img>
                      <img src={newslogo3}></img>
                      <img src={newslogo4}></img>
                      <img src={newslogo5}></img>
                      <img src={newslogo6}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="section-1-content">
                  <h1>Less than 24 hours left to enroll in benefits in {cityStateResponse.state}</h1>
                  <p>Thanks to a new government subsidiary program, {cityStateResponse.state} residents could get $0 Free Health Insurance and up to $1400* allowance card for the everyday expense including No-Cost Vision & No-Cost Dental Benefits.</p>
                  <div className="cta-holder">
                    <Link to={"income"} id="cta-click">
                      I Want My Card
                    </Link>
                  </div>
                </div>
                <div className="item-benefits">
                  <ul>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img src={groceryImage} className="cardimg grocery"></img>
                        </div>
                        <div className="benefitHeadline">
                          <h3>Groceries</h3>
                          <p>You are entitled to a free flex card to buy healthy food.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img src={rentImage} className="cardimg rent"></img>
                        </div>
                        <div className="benefitHeadline">
                          <h3>Rent</h3>
                          <p>You are entitled to a free flex card to pay the house rent.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img src={gasImage} className="cardimg gas"></img>
                        </div>
                        <div className="benefitHeadline">
                          <h3>Gas</h3>
                          <p>You are entitled to a free flex card to pay for gas.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img src={shoppingImage} className="cardimg shopping"></img>
                        </div>
                        <div className="benefitHeadline">
                          <h3>Shopping</h3>
                          <p>You are entitled to a free flex card to pay for other benefit programs.</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="section2">
            <div className="container">
              <div className="row">
                <div className="section2-headline-container">
                  <div className="section2-headline">
                    <h1>Transforming Lives: The Impact of the Subsidy Program</h1>
                    <p>Dive into the remarkable journey of the Subsidy Program, a pivotal initiative reshaping the financial landscape for low-wage Americans. Witness the significant strides made in providing essential support for groceries, rent, gas, and bills. Here's a snapshot of the monumental impact and ongoing progress:</p>
                  </div>
                </div>
                <div className="section2-stat-counter">
                  <div className="statCounter">
                    <div className="statCounterImage">
                      <img src={fi33887} alt="" />
                    </div>
                    <div className="statCounterStats">
                      <h3>$43 Million</h3>
                      <p>Subsidies Allocated</p>
                    </div>
                  </div>
                  <div className="statCounter">
                    <div className="statCounterImage">
                      <img src={fi33888} alt="" />
                    </div>
                    <div className="statCounterStats">
                      <h3>250,000+</h3>
                      <p>People Served</p>
                    </div>
                  </div>
                  <div className="statCounter">
                    <div className="statCounterImage">
                      <img src={fi33889} alt="" />
                    </div>
                    <div className="statCounterStats">
                      <h3>1 in 3</h3>
                      <p>Population Qualifies</p>
                    </div>
                  </div>
                  <div className="statCounter">
                    <div className="statCounterImage">
                      <img src={fi33890} alt="" />
                    </div>
                    <div className="statCounterStats">
                      <h3>$482 Seats</h3>
                      <p>Seats Remaining</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h1>Try Yourself Today!</h1>
                </div>
                <div className="testimonial-content-holder">
                  <p>
                    We strive to offer you the best service, as evidenced by our
                    Trustpilot star rating.
                  </p>
                  <img src={testimonial} alt="" />
                </div>
                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <img src={user1} className="userimg"></img>
                        <h3>Floyd Miles</h3>
                      </div>
                      <div className="testimonial-bottom">
                        <p>
                          I just received mine fast! They worked tirelessly to
                          secure the financial subsidy I needed, and their
                          dedication to customer satisfaction is unmatched.
                        </p>
                      </div>
                      <div className="testimonial-top">
                        <div className="star-rating">
                          <img src={star} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <img src={user2} className="userimg"></img>
                        <h3>Michael Anderson</h3>
                      </div>
                      <div className="testimonial-bottom">
                        <p>
                          Just in time when i need it the most! I can't thank US Support enough for their prompt assistance. Their team's commitment to me is truly commendable.
                        </p>
                      </div>
                      <div className="testimonial-top">
                        <div className="star-rating">
                          <img src={star} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <img src={user4} className="userimg"></img>
                        <h3>Olivia Martinez</h3>
                      </div>
                      <div className="testimonial-bottom">
                        <p>
                          I was in a financial bind, and US Support came to the rescue. Their team's dedication and service to people are top-notch, and I couldn't be happier with their help.
                        </p>
                      </div>
                      <div className="testimonial-top">
                        <div className="star-rating">
                          <img src={star} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container top-footer">
              <div className="row">
                <div className="footer-content">
                  <p>
                    {window.domain_settings.websiteName} is not an insurance or
                    operating company but connects individuals with insurance
                    providers and other affiliates. Plans are insured or covered
                    by a Medicare Advantage organization with a Medicare
                    contract and/or a Medicare-approved Part D
                    sponsor.Enrollment in the plan depends on the plan’s
                    contract renewal with Medicare. Possible options include,
                    but are not limited to Major Medical Plans, Short Term
                    Plans, Christian Health Plans, Health Sharing Plans,
                    discount cards and Fixed Indemnity Plans.Descriptions are
                    for informational purposes only and subject to change.
                    {" " + window.domain_settings.websiteName} is not affiliated
                    with any insurance plan nor does it represent or endorse any
                    plan. We do not offer every plan available in your area. Any
                    information we provide is limited to those plans we do offer
                    in your area. Please contact Medicare.gov or 1–800 MEDICARE
                    to get information on all of your options. By using this
                    site, you acknowledge that you have read and agree to the
                    Terms of Service, and Privacy Policy. Not affiliated with
                    the U. S. government or federal Medicare program. A licensed
                    agent may contact you regarding this insurance-related
                    information.
                  </p>
                </div>
              </div>
            </div>
            <div className="container bottom-footer">
              <div className="row">
                <div className="footer-navigation">
                  <div className="footer-copyright">
                    <p>© Copyright {window.domain_settings.websiteTitle} 2023</p>
                  </div>
                  <div className="footer-navigation-wrapper">
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms of Use</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingCard />
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
